
import BfeForAddress from "~/graphql/Property/BfeForAddress.gql";
import Search from "~/graphql/Search/Search.gql";
import { mapWritableState } from "pinia";
import { useSearchStore } from "~/composables/stores/search";

import { FreeTextFilter } from "~/helpers/filter-helpers.js";

export default {
  props: {
    searchTerm: {
      type: String,
      required: true,
    },

    show: {
      type: Boolean,
      required: true,
    },

    sanitizeValueFunction: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      fetchTimestamp: null,
      fetching: false,
      searchTermInternal: "",
      setSearchTermInternal: false,
      debouncer: null,
      debouncing: false,
      debounceTimestamp: null,
      searchResults: [],
      vejnavn: null,
      dawaSearch: false,
      includedTypes: [
        "STREET",
        "COMPANY",
        "PERSON",
        "LIST",
        "PLOT",
        "BFE",
        ...((this.$isFeatureSupported("portfolio") && ["ASSET_MANAGEMENT_PORTFOLIO", "ASSET_MANAGEMENT_PROPERTY", "ASSET_MANAGEMENT_TENANCY"]) || []),
      ],
    };
  },

  computed: {
    combinedResults() {
      if (this.searchTermInternal.length <= 2) {
        return this.lastSearches;
      } else {
        return this.searchResults;
      }
    },

    lastSearches() {
      let storedSearches = this.$store.getters["search/lastSearches"];
      return [...storedSearches].map((s) => {
        s.text = s.search.searchTerm;
        s.type = s.type.toUpperCase();
        return s;
      });
    },

    loading() {
      return this.fetching || this.debouncing;
    },

    ...mapWritableState(useSearchStore, {
      searchStoreResults: "results",
      searchStoreSelectedItem: "selectedItem",
      searchStoreSelection: "selection",
    }),
  },

  watch: {
    searchTerm: {
      handler(val) {
        this.searchTermInternal = this.sanitizeLabelString(val);
      },
      immediate: true,
    },

    searchTermInternal(val) {
      const searchTerm = val;

      this.debouncing = !this.setSearchTermInternal && searchTerm.length > 2;

      this.setSearchTermInternal = false;

      clearTimeout(this.debouncer);

      if (!this.debouncing) {
        this.searchResults = [];
        return;
      }

      const timestamp = Date.now();
      this.debounceTimestamp = timestamp;

      this.debouncer = setTimeout(() => {
        if (this.debounceTimestamp !== timestamp) return;

        this.search(searchTerm);

        this.debouncing = false;
      }, 280);
    },

    combinedResults(results) {
      this.searchStoreResults = results;
    },

    searchStoreSelectedItem(val) {
      if (val) this.initiateSearch(val);
    },
  },

  methods: {
    setSearchTerm(val) {
      this.setSearchTermInternal = true;
      this.searchTermInternal = this.sanitizeLabelString(val);
    },

    emitItemSelected(text) {
      this.setSearchTerm(text);
      this.$emit("item-selected", text);
    },

    fetch(promise) {
      const fetchTimestamp = Date.now();
      this.fetchTimestamp = fetchTimestamp;
      this.fetching = true;

      return new Promise(async (res, rej) => {
        try {
          const fetchResult = await promise;

          if (this.fetchTimestamp === fetchTimestamp) {
            res(fetchResult);
            this.fetching = false;
          }
        } catch (err) {
          rej(err);
          this.fetching = false;
        }
      });
    },

    async search(val) {
      if (!this.dawaSearch) {
        let result = await this.fetch(
          this.$apollo.query({
            query: Search,
            variables: {
              input: {
                text: val,
                limit: 16,
                offset: 0,
                includedTypes: this.includedTypes,
              },
            },
            errorPolicy: "ignore",
          })
        );

        let resultsOut = [];

        if (!result.data?.search) {
          console.error("No search result", result);

          return;
        }

        result.data.search.hits.forEach((result) => {
          resultsOut.push({
            type: result.typeOf,
            text: result.text,
            id: result.entityID,
            fields: structuredClone(result.fields),
          });
        });

        resultsOut = resultsOut.sort(this.sortSearches);

        resultsOut.unshift({
          type: "EXPLORE",
          text: this.$t("AUTOCOMPLETE_EXPLORE_SUGGEST_LABEL", [val]),
          id: 0,
          fields: null,
        });

        this.searchResults = resultsOut;
      }
      this.dawaSearch = false;
    },

    initiateSearch(item) {
      if (!item) {
        return;
      }

      if (this.searchTermInternal.length <= 2) {
        this.searchFromHistory(item);
      } else if (item.type == "COMPANY") {
        this.searchCompany(item);
      } else if (item.type == "PERSON") {
        this.searchPerson(item);
      } else if (item.type == "LIST") {
        this.searchList(item);
      } else if (item.type == "PLOT") {
        this.searchCadastral(item);
      } else if (item.type == "BFE") {
        this.searchBFE(item);
      } else if (item.type == "EXPLORE") {
        this.searchExplore();
      } else if (item.type == "ASSET_MANAGEMENT_PORTFOLIO") {
        this.searchAssetManagement(item, "initiateAssetManagementPortfolioSearch");
      } else if (item.type == "ASSET_MANAGEMENT_PROPERTY") {
        this.searchAssetManagement(item, "initiateAssetManagementPropertySearch");
      } else if (item.type == "ASSET_MANAGEMENT_TENANCY") {
        this.searchAssetManagement(item, "initiateAssetManagementTenancySearch");
      } else {
        this.searchAddress(item);
      }
    },

    removeSearch(index) {
      this.$store.dispatch("search/removeFromLastSearches", index);
    },

    searchExplore() {
      this.$emit("item-selected", this.searchTermInternal);
      this.$store.commit("msga/upsertFilter", { ...FreeTextFilter, value: this.searchTermInternal });
      this.$router.push({ name: "explore" });
    },

    searchAddress(item) {
      if (item != null) {
        this.setSearchTerm(item.text);
        if (!item.id || item.id == "null") {
          this.dawaSearch = true;
          this.autoCompleteAdresses(item.text);
        } else {
          this.dawaSearch = false;
          this.searchType = "vejnavn";
          this.$emit("item-selected", item.text);
          this.fetch(
            this.$apollo.query({
              query: BfeForAddress,
              variables: {
                addressId: item.id,
                accessAddressId: item.id,
              },
            })
          ).then((resp) => {
            // If not should we make the user aware of the error?
            if (resp.data.bfeForAddress) {
              const search = {
                id: resp.data.bfeForAddress,
                searchTerm: item.text,
              };
              this.$store.dispatch("search/initiateAddressSearch", {
                search,
                save: true,
                track: "text search",
              });
            } else {
              //If this is a plot with no buildings or units we end up here. We'll fetch ejerlav/mat and try again.
              const id = item.data.adgangsadresseid != null ? item.data.adgangsadresseid : item.data.id;
              this.fetch(this.$axios.$get("https://dawa.aws.dk/adgangsadresser/" + id)).then((response) => {
                const search = {
                  id: response.id,
                  matrikel: response.jordstykke.matrikelnr,
                  ejerlav: response.jordstykke.ejerlav.kode,
                  municipality: response.jordstykke.ejerlav.navn,
                  ejerlavText: response.jordstykke.ejerlav.kode + " - " + response.jordstykke.ejerlav.navn,
                  searchTerm: item.text,
                };

                this.$store.dispatch("search/initiateDawaCadastralSearch", {
                  search,
                  save: true,
                  track: "text search",
                });
              });
            }
          });
        }
      }
    },

    autoCompleteAdresses(input) {
      let caretPos = 0;
      let options = {
        per_side: 5,
        q: input,
        type: "adresse",
        caretPos: caretPos,
        supplerendebynavn: true,
        stormodtagerpostnumre: true,
        multilinje: true,
        fuzzy: "",
      };

      if (this.vejnavn && !input.includes(this.vejnavn)) {
        this.vejnavn = null;
      }
      const tester = (x) => x.type == "STREET" && x.text.toLowerCase().replaceAll(" ", "") === input.toLowerCase().replaceAll(" ", "");

      if (this.searchResults.some(tester)) {
        this.vejnavn = input;
      }

      if (this.vejnavn) {
        options.startfra = "adgangsadresse";
      }

      this.fetch(this.$axios.$get("https://dawa.aws.dk/autocomplete?", { params: options })).then((items) => {
        if (items.length == 1) {
          this.$emit("item-selected", input);

          this.fetch(
            this.$apollo.query({
              query: BfeForAddress,
              variables: {
                addressId: items[0].data.id,
                accessAddressId: items[0].data.id,
              },
            })
          ).then((resp) => {
            // If not should we make the user aware of the error?
            if (resp.data.bfeForAddress) {
              const search = {
                id: resp.data.bfeForAddress,
                searchTerm: input,
              };
              this.$store.dispatch("search/initiateAddressSearch", {
                search,
                save: true,
                track: "text search",
              });
            } else {
              //If this is a plot with no buildings or units we end up here. We'll fetch ejerlav/mat and try again.
              const id = items[0].data.adgangsadresseid != null ? items[0].data.adgangsadresseid : items[0].data.id;
              this.fetch(this.$axios.$get("https://dawa.aws.dk/adgangsadresser/" + id)).then((response) => {
                const search = {
                  id: response.id,
                  matrikel: response.jordstykke.matrikelnr,
                  ejerlav: response.jordstykke.ejerlav.kode,
                  municipality: response.jordstykke.ejerlav.navn,
                  ejerlavText: response.jordstykke.ejerlav.kode + " - " + response.jordstykke.ejerlav.navn,
                  searchTerm: response.adressebetegnelse,
                };

                this.$store.dispatch("search/initiateDawaCadastralSearch", {
                  search,
                  save: true,
                  track: "text search",
                });
              });
            }
          });
        } else {
          this.searchResults = items.map((x) => {
            x.type = "ADDRESS";
            x.id = x.data.id;
            x.text = x.tekst;
            return x;
          });
        }
      });
    },

    searchCadastral(item) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch("search/initiateCadastralSearch", {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchBFE(item) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch("search/initiateBFESearch", {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchCompany(item) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch("search/initiateCompanySearch", {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchPerson(item) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch("search/initiatePersonSearch", {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchList(item) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch("search/initiateListSearch", {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchAssetManagement(item, storeSearch) {
      if (item != null) {
        const search = {
          id: item.id,
          searchTerm: item.text,
        };
        this.$store.dispatch(`search/${storeSearch}`, {
          search,
          save: true,
          track: "text search",
        });
        this.emitItemSelected(item);
      }
    },

    searchFromHistory(search) {
      if (!search) return;

      this.$store.dispatch("search/initiateHistorySearch", search);
      this.emitItemSelected(search.search.searchTerm);
    },

    sanitizeLabelString(string) {
      if (this.sanitizeValueFunction !== null) {
        return this.sanitizeValueFunction(string);
      }

      return string;
    },

    sortSearches(a, b) {
      //first sort by portfolio
      if (a.type == "ASSET_MANAGEMENT_PORTFOLIO" && b.type != "ASSET_MANAGEMENT_PORTFOLIO") {
        return -1;
      }
      if (a.type != "ASSET_MANAGEMENT_PORTFOLIO" && b.type == "ASSET_MANAGEMENT_PORTFOLIO") {
        return 1;
      }

      // then sort by asset management property
      if (a.type == "ASSET_MANAGEMENT_PROPERTY" && b.type != "ASSET_MANAGEMENT_PROPERTY") {
        return -1;
      }
      if (a.type != "ASSET_MANAGEMENT_PROPERTY" && b.type == "ASSET_MANAGEMENT_PROPERTY") {
        return 1;
      }

      // then sort by asset management unit
      if (a.type == "ASSET_MANAGEMENT_TENANCY" && b.type != "ASSET_MANAGEMENT_TENANCY") {
        return -1;
      }
      if (a.type != "ASSET_MANAGEMENT_TENANCY" && b.type == "ASSET_MANAGEMENT_TENANCY") {
        return 1;
      }

      //then sort by street
      if (a.type == "STREET" && b.type != "STREET") {
        return -1;
      }
      if (a.type != "STREET" && b.type == "STREET") {
        return 1;
      }

      //then sort by company
      if (a.type == "COMPANY" && b.type != "COMPANY") {
        return -1;
      }
      if (a.type != "COMPANY" && b.type == "COMPANY") {
        return 1;
      }

      //then sort by person
      if (a.type == "PERSON" && b.type != "PERSON") {
        return -1;
      }
      if (a.type != "PERSON" && b.type == "PERSON") {
        return 1;
      }

      //then sort by list
      if (a.type == "LIST" && b.type != "LIST") {
        return -1;
      }
      if (a.type != "LIST" && b.type == "LIST") {
        return 1;
      }

      //then sort by cadastral
      if (a.type == "PLOT" && b.type != "PLOT") {
        return -1;
      }
      if (a.type != "PLOT" && b.type == "PLOT") {
        return 1;
      }

      return 0;
    },
  },
};
