
export default {
  props: {
    color: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    }
  }
};
