import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";
export var mapPlots = function mapPlots(plots) {
  return plots === null || plots === void 0 ? void 0 : plots.map(function (x) {
    var _x$buildings, _x$buildings2, _x$technicalFacilitie;
    if (!x) return x;
    var o = _objectSpread(_objectSpread({}, x), {}, {
      matEjerlav: "".concat(x.matrikelNumber, ", ").concat(x.ownersGuildName),
      noOfBuildings: (_x$buildings = x.buildings) === null || _x$buildings === void 0 ? void 0 : _x$buildings.length,
      noOfUnits: (_x$buildings2 = x.buildings) === null || _x$buildings2 === void 0 ? void 0 : _x$buildings2.reduce(function (a, b) {
        return a + (b !== null && b !== void 0 && b.units ? b.units.length : 0);
      }, 0),
      noOfTechnicalFacilities: (_x$technicalFacilitie = x.technicalFacilities) === null || _x$technicalFacilitie === void 0 ? void 0 : _x$technicalFacilitie.length
    });
    return o;
  });
};
export var mapBuildings = function mapBuildings(buildings, matEjerlav) {
  return buildings === null || buildings === void 0 ? void 0 : buildings.map(function (y) {
    var _y$floors, _y$energyLabel, _y$floors2, _y$floors3, _y$floors4, _y$floors5, _y$floors6, _y$garbageRoomInTerra, _y$garageArea, _y$carportArea, _y$builtinOuthouseAre, _y$enclosedShelterOut, _y$outhouseArea, _y$exteriorReinsulati, _y$otherArea, _y$floors7, _y$floors8, _y$floors9;
    if (!y) return y;
    var o = _objectSpread(_objectSpread({}, y), {}, {
      matEjerlav: matEjerlav,
      addressText: getAddressAsLongString(y.address),
      noOfFloors: (_y$floors = y.floors) === null || _y$floors === void 0 ? void 0 : _y$floors.length,
      energyLabelClassification: (_y$energyLabel = y.energyLabel) === null || _y$energyLabel === void 0 ? void 0 : _y$energyLabel.classification,
      totalBasementArea: (_y$floors2 = y.floors) === null || _y$floors2 === void 0 ? void 0 : _y$floors2.reduce(function (a, f) {
        var _ref;
        if (!f) return 0;
        return a + ((_ref = f.floorTypeText === "Kælder" ? f.floorArea : f.basementArea) !== null && _ref !== void 0 ? _ref : 0);
      }, 0),
      usedBasementArea: (_y$floors3 = y.floors) === null || _y$floors3 === void 0 ? void 0 : _y$floors3.reduce(function (a, f) {
        var _f$commercialBasement, _f$residentialBasemen;
        if (!f) return 0;
        return a + ((_f$commercialBasement = f.commercialBasementArea) !== null && _f$commercialBasement !== void 0 ? _f$commercialBasement : 0) + ((_f$residentialBasemen = f.residentialBasementArea) !== null && _f$residentialBasemen !== void 0 ? _f$residentialBasemen : 0);
      }, 0),
      deepBasementArea: (_y$floors4 = y.floors) === null || _y$floors4 === void 0 ? void 0 : _y$floors4.reduce(function (a, f) {
        var _f$basementArea;
        if (!f) return 0;
        return a + (f.commercialBasementArea === null && f.residentialBasementArea === null ? (_f$basementArea = f.basementArea) !== null && _f$basementArea !== void 0 ? _f$basementArea : 0 : 0);
      }, 0),
      totalAtticArea: (_y$floors5 = y.floors) === null || _y$floors5 === void 0 ? void 0 : _y$floors5.reduce(function (a, f) {
        var _f$floorArea, _f$utilizedAtticArea;
        if (!f) return 0;
        return a + (f.floorTypeText == "Tagetage" ? (_f$floorArea = f.floorArea) !== null && _f$floorArea !== void 0 ? _f$floorArea : 0 : (_f$utilizedAtticArea = f.utilizedAtticArea) !== null && _f$utilizedAtticArea !== void 0 ? _f$utilizedAtticArea : 0);
      }, 0),
      usedAtticArea: (_y$floors6 = y.floors) === null || _y$floors6 === void 0 ? void 0 : _y$floors6.reduce(function (a, f) {
        var _f$utilizedAtticArea2;
        return a + ((_f$utilizedAtticArea2 = f === null || f === void 0 ? void 0 : f.utilizedAtticArea) !== null && _f$utilizedAtticArea2 !== void 0 ? _f$utilizedAtticArea2 : 0);
      }, 0),
      totalOtherArea: ((_y$garbageRoomInTerra = y.garbageRoomInTerrain) !== null && _y$garbageRoomInTerra !== void 0 ? _y$garbageRoomInTerra : 0) + ((_y$garageArea = y.garageArea) !== null && _y$garageArea !== void 0 ? _y$garageArea : 0) + ((_y$carportArea = y.carportArea) !== null && _y$carportArea !== void 0 ? _y$carportArea : 0) + ((_y$builtinOuthouseAre = y.builtinOuthouseArea) !== null && _y$builtinOuthouseAre !== void 0 ? _y$builtinOuthouseAre : 0) + ((_y$enclosedShelterOut = y.enclosedShelterOuthouseArea) !== null && _y$enclosedShelterOut !== void 0 ? _y$enclosedShelterOut : 0) + ((_y$outhouseArea = y.outhouseArea) !== null && _y$outhouseArea !== void 0 ? _y$outhouseArea : 0) + ((_y$exteriorReinsulati = y.exteriorReinsulationArea) !== null && _y$exteriorReinsulati !== void 0 ? _y$exteriorReinsulati : 0) + ((_y$otherArea = y.otherArea) !== null && _y$otherArea !== void 0 ? _y$otherArea : 0),
      legalBasementResidence: (_y$floors7 = y.floors) === null || _y$floors7 === void 0 ? void 0 : _y$floors7.reduce(function (a, f) {
        var _f$residentialBasemen2;
        return a + ((_f$residentialBasemen2 = f === null || f === void 0 ? void 0 : f.residentialBasementArea) !== null && _f$residentialBasemen2 !== void 0 ? _f$residentialBasemen2 : 0);
      }, 0),
      commercialBasementArea: (_y$floors8 = y.floors) === null || _y$floors8 === void 0 ? void 0 : _y$floors8.reduce(function (a, f) {
        var _f$commercialBasement2;
        return a + ((_f$commercialBasement2 = f === null || f === void 0 ? void 0 : f.commercialBasementArea) !== null && _f$commercialBasement2 !== void 0 ? _f$commercialBasement2 : 0);
      }, 0),
      floorsWithoutBasementAndAttic: (_y$floors9 = y.floors) === null || _y$floors9 === void 0 ? void 0 : _y$floors9.reduce(function (a, f) {
        if (!f) return 0;
        return a + (f.floorTypeText != "Tagetage" && f.floorTypeText != "Kælder" ? 1 : 0);
      }, 0),
      totalResidentialArea: y.totalUsableArea
    });
    return o;
  });
};
export var mapUnits = function mapUnits(units, matEjerlav, buildingNumber) {
  return units === null || units === void 0 ? void 0 : units.map(function (z) {
    if (!z) return z;
    var o = _objectSpread(_objectSpread({}, z), {}, {
      addressText: getAddressAsLongString(z.address),
      matEjerlav: matEjerlav,
      buildingNumber: buildingNumber
    });
    return o;
  });
};
export var mapTechnicalFacilities = function mapTechnicalFacilities(technicalFacilities, matEjerlav) {
  return technicalFacilities === null || technicalFacilities === void 0 ? void 0 : technicalFacilities.map(function (y) {
    var o = _objectSpread(_objectSpread({}, y), {}, {
      typeApprovalNr: "??",
      matEjerlav: matEjerlav
    });
    return o;
  });
};
export var Operator = {
  Equals: "eq",
  GreaterThan: "gt",
  GreaterThanOrEqual: "gte",
  LessThan: "lt",
  LessThanOrEqual: "lte",
  In: "in",
  Contains: "ct",
  ContainsCaseInsensitive: "ict",
  StartsWith: "sw",
  StartsWithCaseInsensitive: "isw",
  EndsWith: "ew",
  EndsWithCaseInsensitive: "iew"
};
export var toWritableRef = function toWritableRef(urlParam) {
  return computed({
    get: function get() {
      return urlParam.value.value;
    },
    set: function set(newValue) {
      urlParam.setValue(newValue);
    }
  });
};