import moment from "moment";
import { mapboxLayers } from "../components/map/mapboxLayers";

const mapboxLayerState = mapboxLayers
  .flatMap((mpl) => mpl.layer)
  .reduce((acc, layer) => {
    acc[layer.id] = false;
    return acc;
  }, {});

const mapboxLayerMutations = mapboxLayers
  .flatMap((mpl) => mpl.layer)
  .reduce((acc, layer) => {
    acc[layer.id] = (state, change) => (state[layer.id] = change);
    return acc;
  }, {});

const mapboxLayerGetters = mapboxLayers
  .flatMap((mpl) => mpl.layer)
  .reduce((acc, layer) => {
    acc[layer.id] = (state) => state[layer.id];
    return acc;
  }, {});

export const state = () => ({
  layersMenuActive: false,
  maptype: "default",
  satellite: false,
  // is initially set in mouted() of default layout
  initialLoad: false,
  mapIsReady: false,
  drawingEnabled: false,
  soilLoading: false,
  plotsLoading: false,
  heightLoading: false,

  showPropertyPlots: true,
  showPropertyBuildings: true,
  showOrtoForaar: false,
  showSoilContaminations: false,
  showOilTanks: false,
  showPlots: true,
  showPlotNumbers: true,
  "3DViewEnabled": false,
  poi: false,
  transit: true,
  building_number_label: false,

  ...mapboxLayerState,

  oilTanksPlacementFilter: { name: null, value: null },
  oilTanksDisamblementFilter: { name: null, value: null },

  popupOpen: false,
  popupPosition: null,
  popupType: null,
  popupData: null,
  popupId: null,
  popupLoading: false,
  popupOffset: { bottom: [0, -50] },

  lokalebasenSearchRadius: 1000,
  lokalebasenDateFrom: moment.utc().subtract(1, "year").format(),
  lokalebasenDateTo: moment.utc().format(),
  lokalebasenAreaMin: 0,
  lokalebasenAreaMax: 100000,
  lokalebasenKinds: ["office"],

  mapOmit: false,
  lokalebasenActiveState: undefined,
  showAdvanvcedSearchResultsPins: undefined,
});
export const actions = {
  layersMenuActive({ commit }, change) {
    commit("layersMenuActive", change);
  },
  setPopup({ commit }, { position, type, data = null, id = null, loading = false, offset = { bottom: [0, -50] } }) {
    commit("setPopupOpen", false);
    commit("setPopupPosition", position);
    commit("setPopupType", type);
    commit("setPopupData", data);
    commit("setPopupId", id);
    commit("setPopupOffset", offset);
    commit("setPopupOpen", true);
    commit("setPopupLoading", loading);
  },

  loadInitialState(context) {
    const mapStateNamespace = "preferenceNamespaceMap";

    this.$userPreferences.getNamespace(mapStateNamespace).then((resp) => {
      Object.entries(resp).forEach(([key, value]) => {
        context.commit(key, value);
      });
    });
  },

  setMapStateWithPreferences(context, val) {
    context.commit(val.key, val.value);
    this.$userPreferences.setKey("preferenceNamespaceMap", val.key, val.value);
  },
};
export const mutations = {
  layersMenuActive: (state, change) => (state.layersMenuActive = change),
  maptype: (state, change) => (state.maptype = change),
  showPropertyPlots: (state, change) => (state.showPropertyPlots = change),
  showPropertyBuildings: (state, change) => (state.showPropertyBuildings = change),
  showOrtoForaar: (state, change) => (state.showOrtoForaar = change),
  showSoilContaminations: (state, change) => (state.showSoilContaminations = change),
  showOilTanks: (state, change) => (state.showOilTanks = change),
  showPlots: (state, change) => (state.showPlots = change),
  showPlotNumbers: (state, change) => (state.showPlotNumbers = change),
  oilTanksPlacementFilter: (state, change) => (state.oilTanksPlacementFilter = change),
  oilTanksDisamblementFilter: (state, change) => (state.oilTanksDisamblementFilter = change),
  soilLoading: (state, change) => (state.soilLoading = change),
  heightLoading: (state, change) => (state.heightLoading = change),
  plotsLoading: (state, change) => (state.plotsLoading = change),
  poi: (state, change) => {
    state.poi = change;
  },
  transit: (state, change) => (state.transit = change),

  ...mapboxLayerMutations,

  oversvoemErosion_V: (state, change) => (state.oversvoemErosion_V = change),
  oversvoemErosion_F: (state, change) => (state.oversvoemErosion_F = change),
  setPopupOpen: (state, val) => (state.popupOpen = val),
  setPopupPosition: (state, position) => (state.popupPosition = position),
  setPopupType: (state, type) => (state.popupType = type),
  setPopupData: (state, data) => (state.popupData = data),
  setPopupId: (state, id) => (state.popupId = id),
  setPopupLoading: (state, loading) => (state.popupLoading = loading),
  setPopupOffset: (state, offset) => (state.popupOffset = offset),
  setInitialLoad: (state, bool) => (state.initialLoad = bool),
  satellite: (state, bool) => (state.satellite = bool),
  building_number_label: (state, bool) => (state.building_number_label = bool),
  set3DViewEnabled: (state, bool) => (state["3DViewEnabled"] = bool),
  setMapIsReady: (state, bool) => (state.mapIsReady = bool),
  setDrawingEnabled: (state, bool) => (state.drawingEnabled = bool),
  lokalebasenSearchRadius: (state, change) => (state.lokalebasenSearchRadius = change),
  lokalebasenDateFrom: (state, change) => (state.lokalebasenDateFrom = change),
  lokalebasenDateTo: (state, change) => (state.lokalebasenDateTo = change),
  lokalebasenAreaMin: (state, change) => (state.lokalebasenAreaMin = change),
  lokalebasenAreaMax: (state, change) => (state.lokalebasenAreaMax = change),
  lokalebasenKinds: (state, change) => (state.lokalebasenKinds = change),
  mapOmit: (state, change) => (state.mapOmit = change),
  lokalebasenActiveState: (state, change) => (state.lokalebasenActiveState = change),
  showAdvanvcedSearchResultsPins: (state, change) => (state.showAdvanvcedSearchResultsPins = change),
};

export const getters = {
  layersMenuActive: (state) => state.layersMenuActive,
  maptype: (state) => state.maptype,
  showPropertyPlots: (state) => state.showPropertyPlots,
  showPropertyBuildings: (state) => state.showPropertyBuildings,
  showOrtoForaar: (state) => state.showOrtoForaar,
  showSoilContaminations: (state) => state.showSoilContaminations,
  showOilTanks: (state) => state.showOilTanks,
  showPlots: (state) => state.showPlots,
  showPlotNumbers: (state) => state.showPlotNumbers,
  oilTanksPlacementFilter: (state) => state.oilTanksPlacementFilter,
  oilTanksDisamblementFilter: (state) => state.oilTanksDisamblementFilter,
  soilLoading: (state) => state.soilLoading,
  heightLoading: (state) => state.heightLoading,
  plotsLoading: (state) => state.plotsLoading,
  poi: (state) => state.poi,
  transit: (state) => state.transit,

  ...mapboxLayerGetters,

  getPopupOpen: (state) => state.popupOpen,
  getPopupPosition: (state) => state.popupPosition,
  getPopupType: (state) => state.popupType,
  getPopupData: (state) => state.popupData,
  getPopupId: (state) => state.popupId,
  getPopupLoading: (state) => state.popupLoading,
  getPopupOffset: (state) => state.popupOffset,
  getInitialLoad: (state) => state.initialLoad,
  satellite: (state) => state.satellite,
  building_number_label: (state) => state.building_number_label,
  get3DViewEnabled: (state) => state["3DViewEnabled"],
  getPropertyPinsFilters(state) {
    // OBS: If filter is here, items will NOT be shown
    // see https://docs.mapbox.com/mapbox-gl-js/style-spec/other/#other-filter
    let filters = [];
    if (state.showPropertyBuildings) {
      filters.push(["==", ["get", "type"], "building"]);
      filters.push(["==", ["get", "type"], "condo"]);
      filters.push(["==", ["get", "type"], "buildingOnForeignPlot"]);
    }
    if (state.showOilTanks) {
      filters.push(["all", ["==", ["get", "type"], "facility"], state.oilTanksDisamblementFilter.value, state.oilTanksPlacementFilter.value].filter((x) => x));
    }

    return ["any", ...filters];
  },
  getMapIsReady: (state) => state.mapIsReady,
  getDrawingEnabled: (state) => state.drawingEnabled,
  lokalebasenSearchRadius: (state) => state.lokalebasenSearchRadius,
  lokalebasenDateFrom: (state) => state.lokalebasenDateFrom,
  lokalebasenDateTo: (state) => state.lokalebasenDateTo,
  lokalebasenAreaMin: (state) => state.lokalebasenAreaMin,
  lokalebasenAreaMax: (state) => state.lokalebasenAreaMax,
  lokalebasenKinds: (state) => state.lokalebasenKinds,
  mapOmit: (state) => state.mapOmit,
  lokalebasenActiveState: (state) => state.lokalebasenActiveState,
  showAdvanvcedSearchResultsPins: (state) => state.showAdvanvcedSearchResultsPins,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
