import type translationsDA from "~/translations/da.json";
import type translationsEN from "~/translations/en.json";

export const useThrottle = (func: (...args: any) => any, limit: number) => {
  let inThrottle: boolean = false;

  return function (this: any, ...args: any[]) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const arrayLast = <T>(arr: T[]) => arr[arr.length - 1];

export const hasValue = <T extends unknown>(v: T): v is NonNullable<T> => typeof v !== "undefined" && v !== null;

export const filterWithValue = <T>(arr: Nullable<T>[]) => arr.filter(hasValue) as T[];

export const arrayMutateToggleValue = <T>(arr: T[], v: T, accessor?: (item: T) => boolean) => {
  const index = accessor ? arr.findIndex(accessor) : arr.indexOf(v);

  if (index === -1) arr.push(v);
  else arr.splice(index, 1);

  return arr;
};

export const sleep = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));

class NaNError extends Error {
  message: string = "Not a number";
}

export const prepareNumber = (number: Nullable<number | string>) => {
  if (!hasValue(number) || number === Infinity || number === -Infinity) throw new NaNError();

  if (typeof number === "string") number = parseFloat(number);

  if (isNaN(number)) throw new NaNError();

  return number;
};

export const formatDateString = (string: Nullable<string | number>, formatter: (string: string | number) => string) => {
  if (!hasValue(string)) return "–";

  return formatter(string);
};

export const formatNumber = (number: number | string | null | undefined, formatter: (number: number) => string) => {
  try {
    return formatter(prepareNumber(number));
  } catch (e) {
    if (e instanceof NaNError) return "–";
    else throw e;
  }
};

export type TranslationKey = keyof typeof translationsDA & keyof typeof translationsEN;

export type UIColor = `--${string}-${"white" | `${string}-${number}`}`;
