import { FinancialRecordsDocument } from "~/graphql/generated/graphql";
import { useQuery } from "@vue/apollo-composable";
import moment from "moment";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
export var useFinancialQuery = function useFinancialQuery(args) {
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("filters", function () {
    var entryDateEnd = moment(args.value.period);
    var entryDateStart = moment(args.value.period);
    switch (args.value.periodType) {
      case "MONTHLY":
        entryDateEnd = entryDateEnd.endOf("month");
        break;
      case "QUARTERLY":
        entryDateEnd = entryDateEnd.endOf("quarter");
        break;
      case "YEARLY":
        entryDateEnd = entryDateEnd.endOf("year");
        break;
    }
    return {
      matches: [{
        entry_date: ["lte", entryDateEnd.toISOString()]
      }, {
        entry_date: ["gte", entryDateStart.toISOString()]
      }]
    };
  });
  setQueryFilters("filters", function () {
    var _args$value$account;
    return {
      matches: [{
        account_number: (_args$value$account = args.value.account) === null || _args$value$account === void 0 ? void 0 : _args$value$account.accountNumber
      }]
    };
  });
  setQueryFilters("filters", function () {
    return {
      or: true,
      matches: [{
        property_id: [Operator.In, args.value.propertyIds]
      }, {
        tenancy_id: [Operator.In, args.value.tenancyIds]
      }]
    };
  });
  var query = useQuery(FinancialRecordsDocument, function () {
    return {
      filters: queryFilters.value.filters,
      pagination: args.value.pagination,
      sort: {
        key: "entry_date",
        direction: "DESC"
      }
    };
  }, function () {
    return {
      keepPreviousResult: true
    };
  });
  var result = computed(function () {
    var _query$result$value, _query$result$value$a;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : (_query$result$value$a = _query$result$value.assetManagementFinancialRecords) === null || _query$result$value$a === void 0 ? void 0 : _query$result$value$a.items;
  });
  var metadata = computed(function () {
    var _query$result$value2, _query$result$value2$;
    return (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : (_query$result$value2$ = _query$result$value2.assetManagementFinancialRecords) === null || _query$result$value2$ === void 0 ? void 0 : _query$result$value2$.metadata;
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  return {
    result: result,
    loading: loading,
    metadata: metadata
  };
};