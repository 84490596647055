import { render, staticRenderFns } from "./SettingsNavigationControls.vue?vue&type=template&id=3ccb8f03"
import script from "./SettingsNavigationControls.vue?vue&type=script&setup=true&lang=ts"
export * from "./SettingsNavigationControls.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChromeNavigationControlsItem: require('/app/components/Chrome/ChromeNavigationControlsItem.vue').default,ChromeNavigationControls: require('/app/components/Chrome/ChromeNavigationControls.vue').default})
