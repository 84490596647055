import { getTenancyDefaultUrlQuery } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useDefaultTenancyFilterBookmarkQuery } from "./queries/useDefaultTenancyFilterBookmarkQuery";
import { PERMISSIONS } from "~/composables/usePermissions";
export var useUrlTabsProperty = function useUrlTabsProperty() {
  var t = useTranslator();
  var hasSalesPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_SALES_READ_ORG);
  var hasValuationsPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_VALUATIONS_READ_ORG);
  var defaultFilterQuery = useDefaultTenancyFilterBookmarkQuery(computed(function () {
    return true;
  }));
  return computed(function () {
    var _defaultFilterQuery$r;
    return [{
      key: "overview",
      label: t("PORTFOLIO_PROPERTY_OVERVIEW"),
      default: true
    }, {
      key: "units",
      label: t("PORTFOLIO_PROPERTY_UNITS"),
      query: getTenancyDefaultUrlQuery((_defaultFilterQuery$r = defaultFilterQuery.result.value) === null || _defaultFilterQuery$r === void 0 ? void 0 : _defaultFilterQuery$r.id)
    }, {
      key: "opexes",
      label: t("PORTFOLIO_PROPERTY_OPEX")
    }, {
      key: "capexes",
      label: t("PORTFOLIO_PROPERTY_CAPEX")
    }, {
      key: "sales",
      label: t("PORTFOLIO_PROPERTY_SALES"),
      disabled: !hasSalesPermission.result.value
    }, {
      key: "balance",
      label: t("PORTFOLIO_PROPERTY_BALANCE")
    }, {
      key: "valuations",
      label: t("PORTFOLIO_PROPERTY_VALUATIONS"),
      disabled: !hasValuationsPermission.result.value
    }];
  });
};