function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
/**
 * Example
 *
```ts
const filters = useQueryFilters<AssetManagementTenanciesQueryVariables>();

const getDaysOnMarketFilterById = function (id: number): DaysOnMarketValueConfig {
  return daysOnMarketValues.find((item) => item.id == id) || daysOnMarketValues[0];
};

filters.createRef("filters", {
  compute: () => {
    const { dayMin, dayMax } = getDaysOnMarketFilterById(daysOnMarket.value);
    return DaysOnMarketFilter(dayMin, dayMax);
  },
});

filters.createRef("filters", { compute: TenancyDecomissionedFilter, enabled: () => !inactive.value });

filters.createRef("filters", {
  compute: () => ({ matches: [{ status: ["in", salesStatuses.value] }] }),
  enabled: () => !!salesStatuses.value.length,
});
```

 */
export var useQueryFilters = function useQueryFilters() {
  var filters = ref({});
  var keyedRefs = new Map();
  var setValue = function setValue(filterKey, compute) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var field = filters.value[filterKey] || (filters.value[filterKey] = []);
    var enabled = options.enabled;
    var newRef = computed(function () {
      return enabled && !enabled() ? undefined : compute();
    });
    if (options.key) {
      var _keyedRefs$get;
      var index = (_keyedRefs$get = keyedRefs.get(options.key)) !== null && _keyedRefs$get !== void 0 ? _keyedRefs$get : field.length;
      field[index] = newRef;
      keyedRefs.set(options.key, index);
    } else {
      field.push(newRef);
    }
    return newRef;
  };
  var build = function build() {
    var output = {};
    var filterKey;
    for (filterKey in filters.value) {
      var outputCollection = output[filterKey] || {
        groups: []
      };
      var field = filters.value[filterKey];
      var _iterator = _createForOfIteratorHelper(field),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var filterRef = _step.value;
          var filter = filterRef.value;
          if (filter !== null && filter !== void 0 && filter.matches.length) outputCollection.groups.push(filter);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (outputCollection.groups.length) output[filterKey] = outputCollection;
    }
    return output;
  };
  return {
    value: computed(build),
    setValue: setValue
  };
};