import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.link.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.map.js";
import { arrayMutateToggleValue, hasValue } from "~/helpers/common-helpers";
export var useDataTableRows = function useDataTableRows(headers, data, tableContext, getRowKey, options) {
  var _options$copy, _options$selection, _options$onSelection;
  var onRowClick = options === null || options === void 0 ? void 0 : options.onRowClick;
  var onRowClickEdit = options === null || options === void 0 ? void 0 : options.onRowClickEdit;
  var onRowClickDelete = options === null || options === void 0 ? void 0 : options.onRowClickDelete;
  var hoverEffectStateActive = options === null || options === void 0 ? void 0 : options.hoverEffectStateActive;
  var optionsGetCopyColorRef = options === null || options === void 0 ? void 0 : (_options$copy = options.copy) === null || _options$copy === void 0 ? void 0 : _options$copy.color;
  var t = useTranslator();
  var tUnit = useTranslateUnit();
  var getDataCellContent = function getDataCellContent(row, options, header) {
    var _header$dataTableCell, _options$copyColor, _header$dataTableCell2, _header$dataTableCell3;
    var cellValue = header.value(row);
    if (options.renderAs === "slot") return {
      type: "slot"
    };else if (options.renderAs === "checkbox") return {
      type: "checkbox",
      onSave: function onSave(v) {
        return options.onSave(v, row, header);
      },
      value: Boolean(cellValue)
    };else if (options.renderAs === "checkbox_selection") {
      var checked = options.checked(row);
      return {
        type: "checkbox_selection",
        checked: computed({
          get: function get() {
            return checked.value;
          },
          set: function set() {
            return options.onChange(row);
          }
        })
      };
    } else if (options.renderAs === "dropdown") return {
      type: "dropdown",
      onSave: function onSave(v) {
        return options.onSave(v, row, header);
      },
      value: cellValue,
      options: options.options
    };
    var filter = (_header$dataTableCell = header.dataTableCellOptions) === null || _header$dataTableCell === void 0 ? void 0 : _header$dataTableCell.filter;
    var value = filter ? filter(cellValue, row, tableContext.value) : cellValue;
    var getCopyColorRef = (_options$copyColor = options.copyColor) !== null && _options$copyColor !== void 0 ? _options$copyColor : optionsGetCopyColorRef;
    var color = getCopyColorRef ? getCopyColorRef(row, header) : "--color-black-900";
    var cellContent = {
      type: "copy",
      copy: {
        value: value,
        type: (_header$dataTableCell2 = (_header$dataTableCell3 = header.dataTableCellOptions) === null || _header$dataTableCell3 === void 0 ? void 0 : _header$dataTableCell3.copyType) !== null && _header$dataTableCell2 !== void 0 ? _header$dataTableCell2 : "body",
        size: "medium",
        color: color
      }
    };
    switch (options.renderAs) {
      case "label":
        cellContent.label = {
          color: options.labelColor(row)
        };
        break;
      case "link":
        cellContent.link = {
          navigate: function navigate() {
            return options.navigate(row);
          }
        };
        break;
      case "editable":
        cellContent.copy.value = cellValue;
        cellContent.edit = {
          filter: function filter(v) {
            return options.filter(v, row, tableContext.value);
          },
          type: options.type,
          onSave: function onSave(value) {
            return options.onSave(value, row, header);
          }
        };
        break;
    }
    return cellContent;
  };
  var selection = (_options$selection = options === null || options === void 0 ? void 0 : options.selection) !== null && _options$selection !== void 0 ? _options$selection : options !== null && options !== void 0 && options.checkboxColumn ? ref([]) : undefined;
  var onSelection = (_options$onSelection = options === null || options === void 0 ? void 0 : options.onSelection) !== null && _options$onSelection !== void 0 ? _options$onSelection : options !== null && options !== void 0 && options.checkboxColumn ? function (newSelection) {
    return selection.value = newSelection;
  } : undefined;
  var hasCheckboxColumn = selection && onSelection;
  if (hasCheckboxColumn) {
    headers.value.unshift({
      id: "options_checkbox",
      labelKey: "NONE",
      value: function value() {
        return "";
      },
      dataTableCellOptions: {
        renderAs: "checkbox_selection",
        onChange: function onChange(row) {
          var key = getRowKey(row);
          var newSelection = arrayMutateToggleValue(selection.value.slice(), key);
          onSelection(newSelection);
        },
        checked: function checked(row) {
          return computed(function () {
            return selection.value.includes(getRowKey(row));
          });
        }
      }
    });
  }
  var totalWidthFractions = headers.value.reduce(function (a, b) {
    var _b$widthFractions;
    return a + ((_b$widthFractions = b.widthFractions) !== null && _b$widthFractions !== void 0 ? _b$widthFractions : 0);
  }, 0);
  return computed(function () {
    return {
      rows: data.value.map(function (row) {
        return {
          key: getRowKey(row),
          onClick: onRowClick ? function () {
            return onRowClick(row);
          } : undefined,
          onClickEdit: onRowClickEdit ? function () {
            return onRowClickEdit(row);
          } : undefined,
          onClickDelete: onRowClickDelete ? function () {
            return onRowClickDelete(row);
          } : undefined,
          hoverEffectStateActive: hoverEffectStateActive ? hoverEffectStateActive(row) : undefined,
          cells: headers.value.map(function (header) {
            var _header$dataTableCell4;
            var options = (_header$dataTableCell4 = header.dataTableCellOptions) !== null && _header$dataTableCell4 !== void 0 ? _header$dataTableCell4 : {};
            var onCellClick = header.onClick;
            return {
              key: header.id,
              onClick: onCellClick ? function () {
                return onCellClick(row);
              } : undefined,
              content: getDataCellContent(row, options, header),
              textAlignEnd: header.textAlignEnd
            };
          })
        };
      }),
      headers: headers.value.map(function (header) {
        var _header$label;
        return {
          id: header.id,
          sortDirection: header.sortDirection,
          sortKey: header.sortKey,
          sortRelation: header.sortRelation,
          sortOverrideNullOrder: header.sortOverrideNullOrder,
          copy: header.id === "options_checkbox" ? {
            type: "checkbox_selection",
            checked: computed({
              get: function get() {
                return selection.value.length === data.value.length;
              },
              set: function set(v) {
                return selection.value = v ? data.value.map(getRowKey) : [];
              }
            })
          } : {
            type: "copy",
            value: (_header$label = header.label) !== null && _header$label !== void 0 ? _header$label : t(header.labelKey, {
              area: tUnit(tableContext.value.areaUnit)
            }),
            textAlignEnd: header.textAlignEnd,
            width: header.explodeWidth ? "100%" : hasValue(header.widthFractions) ? "".concat(header.widthFractions * 100 / totalWidthFractions, "%") : "auto",
            minWidth: header.minWidth
          }
        };
      }),
      getSlotName: function getSlotName(row, headerId) {
        return getRowKey(row) + headerId;
      }
    };
  });
};