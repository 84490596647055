import moment from "moment";
import { type AssetManagementTenant } from "~/graphql/generated/graphql";
import { hasValue } from "../common-helpers";
import type { ExternalId } from "./portfolio-tenancy-helpers";

export type Tenant = {
  id: string;
  moveInDate?: Nullable<string>;
  evictionDate?: Nullable<string>;
  latestPlannedRentMetric?: Nullable<RentMetric>;
  assetManagementExternalIDs: {
    items: ExternalId[];
  };
};
export type RentMetric = {
  currency?: Nullable<string>;
  amount?: Nullable<number>;
};
export type Tenants = Tenant[];

export const getRemainingTenants = (currentTenant: AssetManagementTenant, tenants: Tenants) => {
  if (tenants == null || tenants.length === 0) return [];

  if (currentTenant == null) return tenants;

  return [...tenants]
    .sort((a, b) => {
      return isUnikId(getExternalIdForTenant(a)) && isUnikId(getExternalIdForTenant(b)) // Special case for UNIK data as we can use their Id to determine the hostory
        ? unikIdSort(a, b)
        : moment(b.moveInDate).diff(moment(a.moveInDate));
    })
    .filter((tenant) => {
      return tenant.id !== currentTenant.id;
    });
};

export const getExternalIdForTenant = (tenant: {
  assetManagementExternalIDs?: {
    items: { externalID: string; externalIDType?: Nullable<string> }[];
  };
}) => {
  const externalId = tenant.assetManagementExternalIDs?.items.find((item) => {
    return item.externalIDType == "id";
  });

  return externalId ? externalId.externalID : null;
};

export const isVacantTenant = (
  tenant: Nullable<{
    assetManagementExternalIDs?: {
      items: { externalID: string; externalIDType: string }[];
    };
  }>
) => {
  if (!hasValue(tenant)) return true;

  const externalId = getExternalIdForTenant(tenant);

  if (externalId == null || !isUnikId(externalId)) return false;

  return parseInt(externalId.split("-")[3]) % 2 === 1; // In UNIK, odd tenant numbers are vacant
};

export const isUnikId = (id: string | null) => {
  if (id === null) return false;

  const regex = new RegExp("([0-9]*[-]){3}[0-9*]");

  return regex.test(id);
};

export const unikIdSort = function (a: Tenant, b: Tenant) {
  const aId = getExternalIdForTenant(a);
  const bId = getExternalIdForTenant(b);

  if (aId == null || bId == null) return 0;

  const tentantNumberA = parseInt(aId.split("-")[3]);
  const tentantNumberB = parseInt(bId.split("-")[3]);

  return tentantNumberB - tentantNumberA;
};

export const getYearlyRentForTenant = function (tenant: Tenant) {
  return tenant?.latestPlannedRentMetric?.amount || 0;
};

export const getMonthlyRentForTenant = function (tenant: Tenant) {
  const yearly = getYearlyRentForTenant(tenant);

  return hasValue(yearly) ? yearly / 12 : 0;
};
