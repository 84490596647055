/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 11
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/estaid/Estaid/implementation/web)
 */

import amplitude from 'amplitude-js';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * Data to be processed by middleware
 * @typedef MiddlewarePayload
 * @type {object}
 * @property {BaseEvent} event
 * @property {MiddlewareExtra} [extra]
 */

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 * @typedef MiddlewareNext
 * @type {function}
 * @param {MiddlewarePayload} payload
 *
 * @return
 */

/**
 * A function to run on the Event stream (each logEvent call)
 * @typedef Middleware
 * @type {function}
 * @param {MiddlewarePayload} payload The event and extra data being sent
 * @param {MiddlewareNext} next Function to run the next middleware in the chain, not calling next will end the middleware chain
 * @return
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [options]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef {Object} MiddlewareExtra
 * @type {Object.<string, *>}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 */
export const ApiKey = {
  production: 'bc4c2153f9ae427650297510cd54c0e7'
};

export const SpecialEventType = {
  Identify: "$identify",
  Group: "$groupidentify"
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions = {
  plan: {
    version: '11',
    branch: 'main',
    source: 'web',
    versionId: '5f501f45-ba0a-4d41-8460-4af47d188d67'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '1.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = SpecialEventType.Identify;
    this.event_properties = properties;
  }
}

export class AreaSearchLejetjek {
  constructor() {
    this.event_type = 'Area search - Lejetjek';
  }
}

export class ChangeAct {
  constructor() {
    this.event_type = 'Change act';
  }
}

export class CloseServitutfinder {
  constructor() {
    this.event_type = 'Close servitutfinder';
  }
}

export class DownloadAndVerifyEasement {
  constructor(properties) {
    this.event_type = 'Download and verify easement';
    this.event_properties = properties;
  }
}

export class Explore {
  constructor(properties) {
    this.event_type = 'Explore';
    this.event_properties = properties;
  }
}

export class ExportUnits {
  constructor() {
    this.event_type = 'Export units';
  }
}

export class FollowRentRoll {
  constructor() {
    this.event_type = 'Follow rent roll';
  }
}

export class GetLeases {
  constructor() {
    this.event_type = 'Get leases';
  }
}

export class HelpButton {
  constructor() {
    this.event_type = 'Help button';
  }
}

export class LejetjekNoAccessDetails {
  constructor() {
    this.event_type = 'Lejetjek NoAccess Details';
  }
}

export class LejetjekNoAccessUpgrade {
  constructor() {
    this.event_type = 'Lejetjek NoAccess Upgrade';
  }
}

export class LokalebasenNoAccessDetails {
  constructor() {
    this.event_type = 'Lokalebasen NoAccess Details';
  }
}

export class LokalebasenNoAccessUpgrade {
  constructor() {
    this.event_type = 'Lokalebasen NoAccess Upgrade';
  }
}

export class NavigateToExplore {
  constructor() {
    this.event_type = 'Navigate to explore';
  }
}

export class NavigateToPortfolio {
  constructor() {
    this.event_type = 'Navigate to portfolio';
  }
}

export class NavigateToProperties {
  constructor() {
    this.event_type = 'Navigate to properties';
  }
}

export class NavigateToRentRoll {
  constructor() {
    this.event_type = 'Navigate to rent roll';
  }
}

export class NavigateToTab {
  constructor(properties) {
    this.event_type = 'Navigate to tab';
    this.event_properties = properties;
  }
}

export class NextEasement {
  constructor() {
    this.event_type = 'Next easement';
  }
}

export class OpenServitutfinder {
  constructor(properties) {
    this.event_type = 'Open servitutfinder';
    this.event_properties = properties;
  }
}

export class PreviousEasement {
  constructor() {
    this.event_type = 'Previous easement';
  }
}

export class ResetPassword {
  constructor(properties) {
    this.event_type = 'Reset password';
    this.event_properties = properties;
  }
}

export class SaveRentRoll {
  constructor() {
    this.event_type = 'Save rent roll';
  }
}

export class SetFilter {
  constructor(properties) {
    this.event_type = 'Set filter';
    this.event_properties = properties;
  }
}

export class SetFilterPropertyRentalLevels {
  constructor(properties) {
    this.event_type = 'Set filter - property-rental-levels';
    this.event_properties = properties;
  }
}

export class UnfollowRentRoll {
  constructor() {
    this.event_type = 'Unfollow rent roll';
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
    /* @type {Middleware[]} */
    this.middlewares = [];
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...(options?.client?.options ?? options?.client?.config) });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {*} [properties.orgId] Property has no description in tracking plan.
   * @param {*} [properties.orgName] Property has no description in tracking plan.
   * @param {IdentifyOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  identify(userId, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  _identify(event, options) {
    const ampIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        ampIdentify.set(key, value);
      }
    }
    this.amplitude.identify(
      ampIdentify,
      options?.callback,
      options?.errorCallback
    );
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  track(event, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = payload.event.user_properties;
      if (userProperties) {
        const identifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        options?.errorCallback,
      );
    });
  }

  /**
   * Area search - Lejetjek
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Area%20search%20-%20Lejetjek)
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  areaSearchLejetjek(options, extra) {
    this.track(new AreaSearchLejetjek(), options, extra);
  }

  /**
   * Change act
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Change%20act)
   *
   * In servitutfinder, change the viewed act.
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  changeAct(options, extra) {
    this.track(new ChangeAct(), options, extra);
  }

  /**
   * Close servitutfinder
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Close%20servitutfinder)
   *
   * Click the cross in the upper right corner of servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  closeServitutfinder(options, extra) {
    this.track(new CloseServitutfinder(), options, extra);
  }

  /**
   * Download and verify easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Download%20and%20verify%20easement)
   *
   * Click the download and verify button in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.actID The unique ID for a given act containing easements
   * @param {string} properties.easementID Dato/løbenummer, the ID for an easement
   * @param {number} properties.pagesMarked Number of pages marked in servitutfinder
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  downloadAndVerifyEasement(properties, options, extra) {
    this.track(new DownloadAndVerifyEasement(properties), options, extra);
  }

  /**
   * Explore
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Explore)
   *
   * Owner: Mikkel Højland
   *
   * @param {Object} properties The event's properties.
   * @param {string[]} properties.activeFilters Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  explore(properties, options, extra) {
    this.track(new Explore(properties), options, extra);
  }

  /**
   * Export units
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Export%20units)
   *
   * This event tracks the number of times units are exported from our system.
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  exportUnits(options, extra) {
    this.track(new ExportUnits(), options, extra);
  }

  /**
   * Follow rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Follow%20rent%20roll)
   *
   * This event tracks when a user follows a specific rent roll
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  followRentRoll(options, extra) {
    this.track(new FollowRentRoll(), options, extra);
  }

  /**
   * Get leases
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Get%20leases)
   *
   * Owner: Steffen Grøn Andersen
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  getLeases(options, extra) {
    this.track(new GetLeases(), options, extra);
  }

  /**
   * Help button
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Help%20button)
   *
   * Click the help button in the upper right corner of servitut finder
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  helpButton(options, extra) {
    this.track(new HelpButton(), options, extra);
  }

  /**
   * Lejetjek NoAccess Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lejetjek%20NoAccess%20Details)
   *
   * Owner: tc@estaid.dk
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lejetjekNoAccessDetails(options, extra) {
    this.track(new LejetjekNoAccessDetails(), options, extra);
  }

  /**
   * Lejetjek NoAccess Upgrade
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lejetjek%20NoAccess%20Upgrade)
   *
   * Owner: tc@estaid.dk
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lejetjekNoAccessUpgrade(options, extra) {
    this.track(new LejetjekNoAccessUpgrade(), options, extra);
  }

  /**
   * Lokalebasen NoAccess Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lokalebasen%20NoAccess%20Details)
   *
   * Owner: tc@estaid.dk
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lokalebasenNoAccessDetails(options, extra) {
    this.track(new LokalebasenNoAccessDetails(), options, extra);
  }

  /**
   * Lokalebasen NoAccess Upgrade
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Lokalebasen%20NoAccess%20Upgrade)
   *
   * Owner: tc@estaid.dk
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lokalebasenNoAccessUpgrade(options, extra) {
    this.track(new LokalebasenNoAccessUpgrade(), options, extra);
  }

  /**
   * Navigate to explore
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20explore)
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  navigateToExplore(options, extra) {
    this.track(new NavigateToExplore(), options, extra);
  }

  /**
   * Navigate to portfolio
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20portfolio)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  navigateToPortfolio(options, extra) {
    this.track(new NavigateToPortfolio(), options, extra);
  }

  /**
   * Navigate to properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20properties)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  navigateToProperties(options, extra) {
    this.track(new NavigateToProperties(), options, extra);
  }

  /**
   * Navigate to rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20rent%20roll)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  navigateToRentRoll(options, extra) {
    this.track(new NavigateToRentRoll(), options, extra);
  }

  /**
   * Navigate to tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Navigate%20to%20tab)
   *
   * This event records when a user navigates between tabs at different levels, such as Portfolio, Property, or Unit levels, within our system.
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.hierarchy] The "Hierarchy" property indicates the specific level in our system, which can be one of the following values: "Portfolio," "Property," or "Unit." It helps categorize the context of the user's interaction.
   * @param {*} [properties.page] The "Page" property specifies which tab a user has navigated to within our system. It can have values like "Overview," "Capex," "Opex," or "Trial balance," providing insights into the user's focus or area of interest during navigation.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  navigateToTab(properties, options, extra) {
    this.track(new NavigateToTab(properties), options, extra);
  }

  /**
   * Next easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Next%20easement)
   *
   * Navigate to next easement in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  nextEasement(options, extra) {
    this.track(new NextEasement(), options, extra);
  }

  /**
   * Open servitutfinder
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Open%20servitutfinder)
   *
   * The "Find in act" button on a servitut in https://app.estaid.dk/explore/property/6014180/easements
   *
   * Owner: Mikkel Højland
   *
   * @param {Object} properties The event's properties.
   * @param {*} [properties.postalCode] Property has no description in tracking plan.
   * @param {'main_property'|'condo'|'building_on_foreign_plot'|'unknown'} properties.propertyType Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  openServitutfinder(properties, options, extra) {
    this.track(new OpenServitutfinder(properties), options, extra);
  }

  /**
   * Previous easement
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Previous%20easement)
   *
   * Navigate to the previous easement in servitutfinder
   *
   * Owner: Mikkel Højland
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  previousEasement(options, extra) {
    this.track(new PreviousEasement(), options, extra);
  }

  /**
   * Reset password
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Reset%20password)
   *
   * Owner: Morten Esbensen
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.message Property has no description in tracking plan.
   * @param {'SUCCESS'|'ERROR'} [properties.status] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  resetPassword(properties, options, extra) {
    this.track(new ResetPassword(properties), options, extra);
  }

  /**
   * Save rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Save%20rent%20roll)
   *
   * This event tracks when a user saves filters for the rent roll view
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  saveRentRoll(options, extra) {
    this.track(new SaveRentRoll(), options, extra);
  }

  /**
   * Set filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Set%20filter)
   *
   * Records instances when users apply filters, providing insights into how different filters are utilized.
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.filter] Specifies the name of the filter applied by the user.
   * @param {string} [properties.filterField] Identifies the particular field or attribute within the filter that the user interacted with, offering detailed information on the applied filter configuration.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  setFilter(properties, options, extra) {
    this.track(new SetFilter(properties), options, extra);
  }

  /**
   * Set filter - property-rental-levels
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Set%20filter%20-%20property-rental-levels)
   *
   * Owner: Steffen Grøn Andersen
   *
   * @param {Object} [properties] The event's properties.
   * @param {string[]} [properties.Filters] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  setFilterPropertyRentalLevels(properties, options, extra) {
    this.track(new SetFilterPropertyRentalLevels(properties), options, extra);
  }

  /**
   * Unfollow rent roll
   *
   * [View in Tracking Plan](https://data.amplitude.com/estaid/Estaid/events/main/latest/Unfollow%20rent%20roll)
   *
   * This event tracks when a user unfollows a specific rent roll
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  unfollowRentRoll(options, extra) {
    this.track(new UnfollowRentRoll(), options, extra);
  }

  /**
   * Add new middleware to end of chain
   *
   * @param {Middleware} middleware
   */
  addEventMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all middleware
   *
   * @param {MiddlewarePayload} payload
   * @param {MiddlewareNext} next The method to run after all middleware.
   *
   * @protected
   */
  runMiddleware(payload, next) {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();
