import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";
export var featureFlags = [{
  name: "rentalData",
  active: true,
  routes: ["explore-property-id-rental-levels"]
}, {
  name: "rentalDataSimulations",
  active: true,
  routes: []
}, {
  name: "bofpDocuments",
  active: isDev() || isFeature(),
  routes: []
}, {
  name: "personSearchLoginWarningTest",
  active: false,
  routes: []
}, {
  name: "advancedSearchAllResults",
  active: false,
  routes: []
}, {
  name: "freeAccessCommercialRent",
  active: false,
  routes: []
}, {
  name: "folderTemplates",
  active: true,
  routes: []
}, {
  name: "forceThirdPartyError",
  active: false,
  routes: []
}, {
  name: "portfolio",
  active: true,
  routes: ["portfolio", "portfolio-properties", "portfolio-tenancies"]
}, {
  name: "portfolioDataWarehouse",
  active: isDev() || isFeature(),
  routes: ["datawarehouse"]
}, {
  name: "balanceKeyFigures",
  active: isDev() || isFeature(),
  routes: []
}, {
  name: "arrearsBeta",
  active: true,
  routes: []
}, {
  name: "financeAnalytics",
  active: isDev() || isFeature(),
  routes: []
}];
export default (function (context, inject) {
  inject("isRouteSupported", function (route) {
    return isRouteSupported(route);
  });
  inject("isFeatureSupported", function (feature) {
    return isFeatureSupported(feature);
  });
});
function isRouteSupported(route) {
  var flags = featureFlags.filter(function (f) {
    return f.routes.includes(route);
  });
  if (flags.length == 0) {
    return true;
  } else {
    return flags.some(function (f) {
      return f.active;
    });
  }
}
export function isFeatureSupported(feature) {
  var flags = featureFlags.filter(function (f) {
    return f.name === feature;
  });
  if (flags.length == 0) {
    return true;
  } else {
    return flags.some(function (f) {
      return f.active;
    });
  }
}
function isProduction() {
  return process.env.buildVariant === "prod";
}
function isStaging() {
  return process.env.buildVariant === "staging";
}
function isFeature() {
  return process.env.buildVariant === "feature";
}
function isDev() {
  return process.env.buildVariant === "dev";
}