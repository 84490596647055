import { i18n } from "~/plugins/translator";

export const PROPERTY_TYPE_MAIN_PROPERTY = "main_property";
export const PROPERTY_TYPE_CONDO = "condo";
export const PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT = "building_on_foreign_plot";
export const PROPERTY_TYPE_UNKNOWN = "unknown";

/**
 * Returns the type of the property.
 */
export const getPropertyType = function (property) {
  if (property?.plots) {
    return PROPERTY_TYPE_MAIN_PROPERTY;
  } else if (property?.condo) {
    return PROPERTY_TYPE_CONDO;
  } else if (property?.buildingOnForeignPlot) {
    return PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT;
  } else {
    return PROPERTY_TYPE_UNKNOWN;
  }
};

/**
 * Returns the icon of the property.
 */
export const getPropertyIcon = function (property) {
  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_MAIN_PROPERTY:
      return "icon-property-tt";
    case PROPERTY_TYPE_CONDO:
      return "icon-condo-tt";
    case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
      return "icon-building-tt";
    default:
      return "icon-property-unknown-tt";
  }
};

/**
 * Returns an address of the property.
 */
export const getPropertyAddress = function (property) {
  if (property == null) {
    return null;
  }

  if (property.addresses && property.addresses.length > 0) {
    return property.addresses[0];
  }

  if (property.address) {
    return property.address;
  }

  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_CONDO: {
      return property.condo.unit?.address;
    }

    case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT: {
      const buildingWithAddress = property.buildingOnForeignPlot.buildings?.find((building) => building.address);
      return buildingWithAddress?.address;
    }

    case PROPERTY_TYPE_MAIN_PROPERTY: {
      if (property.plots.some((plot) => plot.addresses)) {
        return property.plots.find((plot) => plot.addresses && plot.addresses.length > 0)?.addresses[0];
      }

      if (property.plots.some((plot) => plot.address)) {
        return property.plots.find((plot) => plot.address)?.address;
      }

      return null;
    }

    default: {
      return null;
    }
  }
};

/**
 * Returns a centroid for the property
 */
export const getPropertyCentroid = function (property) {
  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_CONDO: {
      if (property.condo.unit?.address) {
        return property.condo.unit?.address?.centroid;
      } else if (property.condo.property?.plots?.some((plot) => plot.geometry)) {
        return property.condo.property.plots.find((plot) => plot.geometry).geometry.centroid;
      }

      return null;
    }

    case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT: {
      const buildingWithAddress = property.buildingOnForeignPlot.buildings?.find((building) => building.address);
      return buildingWithAddress?.address?.centroid;
    }

    case PROPERTY_TYPE_MAIN_PROPERTY: {
      if (property.plots.some((plot) => plot.geometry)) {
        return property.plots.find((plot) => plot.geometry).geometry.centroid;
      }

      return null;
    }

    default: {
      return null;
    }
  }
};

/**
 * Returns the property type as translated string.
 */
export const getPropertyTypeText = function (property) {
  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_MAIN_PROPERTY:
      return property.isDividedInCondos ? i18n.t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS") : i18n.t("OVERVIEW_TYPE_MAIN_PROPERTY");
    case PROPERTY_TYPE_CONDO:
      return i18n.t("OVERVIEW_TYPE_CONDO");
    case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
      return i18n.t("OVERVIEW_TYPE_BOFP");
    default:
      return i18n.t("VALUE_NOT_REGISTERED");
  }
};

/**
 * Get all buildings on the property
 */
export const getBuildings = function (property) {
  const propertyType = getPropertyType(property);

  if (propertyType === PROPERTY_TYPE_MAIN_PROPERTY) {
    return property.plots.flatMap((plot) => (plot != null ? plot.buildings : [] || [])).filter((building) => building != null);
  } else if (propertyType === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT) {
    return (property.buildingOnForeignPlot.buildings || []).filter((building) => building != null);
  } else {
    return [];
  }
};

/**
 * Get all units on the property
 */
export const getUnits = function (property) {
  const propertyType = getPropertyType(property);

  if (propertyType === PROPERTY_TYPE_CONDO) {
    return property.condo.unit ? [property.condo.unit] : [];
  } else {
    return getBuildings(property)?.flatMap((b) => b?.units || []) || [];
  }
};

/**
 * Get all plots on the property
 */
export const getPlots = function (property) {
  const propertyType = getPropertyType(property);

  if (propertyType === PROPERTY_TYPE_MAIN_PROPERTY) {
    return property.plots;
  } else if (propertyType === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT) {
    return property.buildingOnForeignPlot.property?.plots || [];
  } else {
    return [];
  }
};

/**
 * Get all technical facilities on the property
 */
export const getTechnicalFacilities = function (property) {
  const propertyType = getPropertyType(property);

  if (propertyType === PROPERTY_TYPE_MAIN_PROPERTY) {
    if (!property.plots || property.plots.length === 0) {
      return [];
    }

    return property.plots.flatMap((p) => (p?.technicalFacilities ? p.technicalFacilities : [] || []));
  } else if (propertyType === PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT) {
    return property.buildingOnForeignPlot.technicalFacilities || [];
  } else {
    return [];
  }
};

export const getConstructionYears = function (property) {
  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_CONDO:
      return [property.condo.unit?.building?.constructionYear] || [];
    default: {
      let constructionYears = getBuildings(property)
        .flatMap((b) => b.constructionYear)
        .filter((cy) => cy && cy !== 1000); // Year 1000 is sometimes used in BBR when not registered

      return [...new Set(constructionYears)].sort((a, b) => b - a);
    }
  }
};

export const getExtensionYears = function (property) {
  switch (getPropertyType(property)) {
    case PROPERTY_TYPE_CONDO:
      return [property.condo.unit?.building?.extensionYear] || [];
    default: {
      let extensionYears = getBuildings(property)
        .flatMap((b) => b.extensionYear)
        .filter((cy) => cy && cy !== 1000); // Year 1000 is sometimes used in BBR when not registered

      return [...new Set(extensionYears)].sort((a, b) => b - a);
    }
  }
};

const getEasementDate = function (easement) {
  let out = "";
  const a = (easement.documentIdentification || "").split("-");

  if (a.length > 0) {
    const x = a[0].substr(6, 2);
    const y = a[0].substr(4, 2);
    const z = a[0].substr(0, 4);

    out = `${x}.${y}.${z}-${a[1]}`;

    if (a.length > 2) {
      out += `-${a[2]}`;
    }
  }

  return out;
};

export const createEasementDateId = function (easement) {
  return getEasementDate(easement);
};

/**
 * Returns if the property has a centroid
 */
export const hasPropertyCentroid = function (property) {
  return !!getPropertyCentroid(property);
};

export const mapPropertyRemarks = function (property) {
  return [
    {
      text: i18n.t("PROPERT_REMARK_OBL_TO_OFFER_HEADLINE"),
      value: property ? getPreemptionRights(property).value : false,
      getExplanation: function (value) {
        let numResidentialUnits = getPreemptionRights(property).numResidentialUnits;
        let numCommercialUnits = getPreemptionRights(property).numCommercialUnits;

        return property && value
          ? i18n.t("PROPERT_REMARK_OBL_TO_OFFER_TRUE", [numResidentialUnits, numCommercialUnits])
          : i18n.t("PROPERT_REMARK_OBL_TO_OFFER_FALSE", [numResidentialUnits, numCommercialUnits]);
      },
    },

    {
      text: i18n.t("PROPERT_REMARK_PRESERVED_BUILDINGS_HEADLINE"),
      value: property && getPreservedBuildings(property).length > 0 ? true : false,
      getExplanation: function () {
        let numBuildings = getBuildings(property).length;
        let numPreservedBuildings = getPreservedBuildings(property).length;

        if (numPreservedBuildings > 1) {
          return i18n.t("PROPERT_REMARK_PRESERVED_MORE_BUILDINGS_TRUE", [numPreservedBuildings, numBuildings]);
        } else if (numPreservedBuildings > 0) {
          return i18n.t("PROPERT_REMARK_PRESERVED_ONE_BUILDING_TRUE", [numPreservedBuildings, numBuildings]);
        } else {
          return i18n.t("PROPERT_REMARK_PRESERVED_BUILDINGS_FALSE");
        }
      },
    },

    {
      text: i18n.t("PROPERT_REMARK_SOIL_CONTAMINATION_HEADLINE"),
      value: property ? getSoilContaminations(property).length > 0 : false,
      getExplanation: function () {
        let soilContaminationTypes = getSoilContaminations(property).map((sc) => sc.type);

        if (soilContaminationTypes.includes("V2")) {
          return i18n.t("PROPERT_REMARK_SOIL_CONTAMINATION_V2_TRUE");
        } else if (soilContaminationTypes.includes("V1")) {
          return i18n.t("PROPERT_REMARK_SOIL_CONTAMINATION_V1_TRUE");
        } else {
          return i18n.t("PROPERT_REMARK_SOIL_CONTAMINATION_FALSE");
        }
      },
    },

    {
      text: i18n.t("PROPERT_REMARK_TANK_HEADLINE"),
      value: property && getOlitanks(property).length > 0,
      getExplanation: function () {
        let oiltanks = getOlitanks(property);
        let numOilTanks = oiltanks?.length;

        if (numOilTanks > 1) {
          return i18n.t("PROPERT_REMARK_MORE_TANKS_TRUE", [numOilTanks]);
        } else if (numOilTanks == 1) {
          return i18n.t("PROPERT_REMARK_ONE_TANK_TRUE", [numOilTanks]);
        } else {
          return i18n.t("PROPERT_REMARK_TANK_FALSE");
        }
      },
    },
  ];
};

const getPreemptionRights = function (property) {
  const residentialUnitsWithLeasedCommercialUnitsLimit = 13;
  const residentialUnitsWithoutLeasedCommercialUnitsLimit = 6;

  let numCommercialUnits = getUnits(property)?.filter((unit) => unit.housingTypeCode === "E")?.length || 0;
  let numResidentialUnits = getUnits(property)?.filter((unit) => unit.housingTypeCode != "E")?.length || 0;

  let isCondominiumProperty = getBuildings(property).some((building) => building && building.units && building.units.some((unit) => unit.bfeNumber));

  let hasPreemtionRight =
    !isCondominiumProperty &&
    ((numCommercialUnits > 0 && numResidentialUnits >= residentialUnitsWithLeasedCommercialUnitsLimit) ||
      (numCommercialUnits == 0 && numResidentialUnits >= residentialUnitsWithoutLeasedCommercialUnitsLimit));

  return {
    numResidentialUnits: numResidentialUnits,
    numCommercialUnits: numCommercialUnits,
    value: hasPreemtionRight,
  };
};

const getSoilContaminations = function (property) {
  return (
    property?.plots
      ?.flatMap((plot) => {
        if (plot && plot.geometry && plot.geometry.pollutions && plot.geometry.pollutions.length != 0) {
          return plot.geometry.pollutions;
        }
      })
      ?.filter((sc) => sc != null) ?? []
  );
};

const getPreservedBuildings = function (property) {
  return getBuildings(property).filter((building) => building && building.conservationCode && building.conservationCode != "");
};

const getOlitanks = function (property) {
  const classificationCodeTank = 1110;
  const locationCodeBurrowed = 1;

  return (
    getTechnicalFacilities(property).filter(
      (tf) => tf != null && tf.classificationCode == classificationCodeTank && tf.locationCode == locationCodeBurrowed && !tf.oilTankDemolitionConditionCode
    ) || []
  );
};
