// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_53k5G{display:flex;flex-direction:row;margin-bottom:1px;width:100%}.container_53k5G.thickBoi_9HlZG{margin-bottom:.25rem}.container_53k5G:last-child{margin-bottom:0}.widgetEnd_-8Q5j,.widgetStart_Uf7f7{display:flex;flex-direction:column;width:50%}.widgetStart_Uf7f7{margin-right:1px;width:calc(50% - 1px)}.widgetStart_Uf7f7.offsetSplit_mDpCE{width:calc(33.33333% - 1px)}.widgetEnd_-8Q5j.offsetSplit_mDpCE{width:66.666666666%}.widgetStart_Uf7f7.offsetSplit_mDpCE.reverseSplit_FGmLk{width:calc(66.66667% - 1px)}.widgetEnd_-8Q5j.offsetSplit_mDpCE.reverseSplit_FGmLk{width:33.333333333%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_53k5G",
	"thickBoi": "thickBoi_9HlZG",
	"widgetEnd": "widgetEnd_-8Q5j",
	"widgetStart": "widgetStart_Uf7f7",
	"offsetSplit": "offsetSplit_mDpCE",
	"reverseSplit": "reverseSplit_FGmLk"
};
module.exports = ___CSS_LOADER_EXPORT___;
