import { ampli } from "~/ampli/index.js";
export default function (context, inject) {
  ampli.load({
    environment: process.env.buildVariant === "prod" ? "production" : "development",
    client: {
      apiKey: "bc4c2153f9ae427650297510cd54c0e7",
      options: {
        apiEndpoint: "collect.estaid.dk"
      }
    }
  });
  inject("amplitude", ampli);
}
;
export { ampli };