// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_6Jixb{align-items:center;display:flex;flex-direction:row;width:100%}.topBarSeatch_5BMJi{align-items:space-between;display:flex;justify-content:center;width:100%}.containerAutocomplete_xXH-C{height:-moz-fit-content;height:fit-content;position:absolute;top:3.25rem;width:max(44rem,min(33%,50%));z-index:2}.containerSearch_KSUUt{-webkit-overflow-scrolling:touch;align-items:center;display:flex;overflow-y:auto;padding:.25rem;scroll-behavior:smooth;scrollbar-color:var(--color-supportive-3-500) var(--color-supportive-3-100);scrollbar-width:thin}.containerSearch_KSUUt::-webkit-scrollbar{height:1rem;width:0}.containerSearch_KSUUt::-webkit-scrollbar-track{background:var(--color-supportive-3-100)}.containerSearch_KSUUt::-webkit-scrollbar-thumb{background-color:var(--color-supportive-3-500);border:.25rem solid var(--color-supportive-3-100);border-radius:calc(1rem - var(--border-radius-medium))}.containerSearch_KSUUt::-webkit-scrollbar-thumb:hover{border-width:.2rem}.containerSearch_KSUUt{width:max(44rem,min(33%,50%))}.input_pqa0K{display:flex;width:100%}.focus_QkGcA{box-shadow:var(--box-shadow-small)}.controls_9lwtE{align-items:center;border-left:1px solid var(--color-supportive-3-400);display:flex;flex-direction:row;margin-left:1rem;padding-left:.5rem;-webkit-text-decoration:underline;text-decoration:underline}.controls_9lwtE:hover{cursor:pointer;-webkit-text-decoration:none;text-decoration:none}.label_SRRxU{margin-left:.25rem;position:relative}.badge_9V4MG,.label_SRRxU{align-items:center;display:flex}.badge_9V4MG{background-color:var(--color-purple-500);border-radius:1.25rem;height:1.25rem;justify-content:center;position:absolute;right:-1.75rem;width:1.25rem}.advanced_jPVRk{display:flex;padding-right:.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_6Jixb",
	"topBarSeatch": "topBarSeatch_5BMJi",
	"containerAutocomplete": "containerAutocomplete_xXH-C",
	"containerSearch": "containerSearch_KSUUt",
	"input": "input_pqa0K",
	"focus": "focus_QkGcA",
	"controls": "controls_9lwtE",
	"label": "label_SRRxU",
	"badge": "badge_9V4MG",
	"advanced": "advanced_jPVRk"
};
module.exports = ___CSS_LOADER_EXPORT___;
