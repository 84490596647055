
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DragCircleMode from "./DragCircleMode";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { mapboxDrawStyles } from "../MapBoxDrawConfig.js";

export default {
  name: "MapDrawManager",

  drawManager: null,

  data() {
    return {
      polygonFilter: {
        id: "polygon",
        type: "polygon",
        label: this.$t("filters.polygon"),
        elastic: "centroidGIS",
        enabled: true,
      },
    };
  },

  mounted() {
    if (this.$options.drawManager) {
      return;
    }

    this.$options.drawManager = new MapboxDraw({
      displayControlsDefault: false,
      keybinding: false,
      modes: {
        simple_select: MapboxDraw.modes.simple_select,
        direct_select: MapboxDraw.modes.direct_select,
        draw_polygon: MapboxDraw.modes.draw_polygon,
        draw_circle: DragCircleMode,
      },
      styles: mapboxDrawStyles,
    });
    this.addDrawLayer();
    this.$map.then((map) => {
      map?.on("draw.create", this.drawCreate);
      map?.on("draw.update", this.drawUpdate);
      map?.on("draw.deleteAllDrawings", this.drawDeleteAll);
      map?.on("draw.setDrawMode", this.setDrawMode);
    });

    this.$store.watch(
      () => {
        return this.$store.getters["msga/getFilters"];
      },
      (val) => {
        const polygonFilter = val.find((x) => x.type === "polygon");

        if (polygonFilter == null) {
          this.$map.then((map) => {
            if (map?.hasControl(this.$options.drawManager)) {
              this.$options.drawManager.deleteAll();
              map.removeControl(this.$options.drawManager);
              this.$store.commit("mapstate/setDrawingEnabled", false);
            }
          });
        } else {
          this.$map.then((map) => {
            this.$options.drawManager.options.styles.forEach((style) => {
              map?.setLayoutProperty(style.id, "visibility", polygonFilter.active ? "visible" : "none");
            });
          });
        }
      }
    );
  },

  beforeDestroy() {
    if (this.$options.drawManager) {
      this.$map.then((map) => {
        map.removeControl(this.$options.drawManager);
      });
    }
  },

  methods: {
    addDrawLayer(drawMode) {
      this.$map.then((map) => {
        if (map && !map.hasControl(this.$options.drawManager)) {
          map.addControl(this.$options.drawManager);
          ["explore_property_pins", "property_pins"].forEach((layer) => {
            map.moveLayer(layer);
          });
        }

        if (drawMode) {
          this.$options.drawManager.changeMode(drawMode);
        }
      });
    },

    removeDrawlayer() {
      if (this.$options.drawManager) {
        this.$map.then((map) => {
          map?.removeControl(this.$options.drawManager);
        });
      }
    },

    drawCreate(e) {
      this.updateStoreWithDrawings(e);
      this.$store.commit("mapstate/setPopupType", "AreaWindow");
      this.$store.commit("mapstate/setPopupOpen", true);
    },

    drawUpdate(e) {
      this.updateStoreWithDrawings(e);
      this.$store.commit("mapstate/setPopupOpen", true);
    },

    updateStoreWithDrawings(e) {
      const obj = this.$options.drawManager.getAll().features[0];
      if (!obj) {
        return;
      } else {
        this.$store.commit("areas/setDrawnCoordinates", obj.geometry.coordinates);
      }
    },

    drawDeleteAll() {
      this.$store.commit("areas/setDrawnCoordinates", []);
      this.$store.commit("areas/setCoordinates", []);
      this.$options.drawManager?.deleteAll();
      this.$store.commit("msga/removeFilter", this.polygonFilter);
    },

    setDrawMode(e) {
      this.addDrawLayer(e.mode);
    },
  },
};
