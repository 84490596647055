
export default {
  props: {
    colorBackground: {
      type: String,
      required: true,
    },

    rounded: {
      type: Boolean,
      default: false,
    },

    shadow: {
      type: Boolean,
      default: false,
    },
  },
};
