
export default {
  props: {
    offsetSplit: {
      type: Boolean,
      default: false,
    },

    reverseSplit: {
      type: Boolean,
      default: false,
    },

    thickBoi: {
      type: Boolean,
      default: false,
    },
  },
};
